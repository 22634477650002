import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { Button, ButtonGroup, Hidden } from '@material-ui/core';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { parseDate } from 'core/utils/formatters';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import Toggle from 'core/ui/Toggle';
import { primary } from 'core/ui/Colors';
import { WorkoutForm } from './Modals';

const useStyles = makeStyles(() => ({
  btnTypeDisabled: {
    backgroundColor: 'rgba(64, 64, 65, 1)!important',
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(64, 64, 65, 0.8)!important',
    },
  },
}));

const GridView = ({
  calendarRef,
  setCurrentDate,
  calendarDates,
  setCalendarDates,
  buttonColor,
  hidePrograms,
}) => {
  const classes = useStyles();

  const type = calendarDates?.currentView;

  const gridView = (newType) => {
    if (newType === 'timeGridProgram') {
      calendarRef.current?.getApi()?.changeView('timeGridWeek');
    } else {
      calendarRef.current?.getApi()?.changeView(newType);
    }
    setCalendarDates((prev) => ({ ...prev, currentView: newType }));
    setCurrentDate(calendarRef.current?.getApi()?.view?.title);
  };

  useEffect(() => {
    if (isMobile) {
      gridView('timeGridDay');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ButtonGroup variant="contained" color="secondary">
      <Button
        className={type !== 'dayGridMonth' && classes.btnTypeDisabled}
        style={{ backgroundColor: buttonColor }}
        onClick={() => gridView('dayGridMonth')}
      >
        month
      </Button>
      <Button
        className={type !== 'timeGridWeek' && classes.btnTypeDisabled}
        onClick={() => gridView('timeGridWeek')}
        style={{ backgroundColor: buttonColor }}
      >
        week
      </Button>
      <Button
        className={type !== 'timeGridDay' && classes.btnTypeDisabled}
        onClick={() => gridView('timeGridDay')}
        style={{ backgroundColor: buttonColor }}
      >
        day
      </Button>
      {!hidePrograms && (
        <Button
          className={type !== 'timeGridProgram' && classes.btnTypeDisabled}
          onClick={() => gridView('timeGridProgram')}
          style={{ backgroundColor: buttonColor }}
        >
          program
        </Button>
      )}
    </ButtonGroup>
  );
};

GridView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  calendarRef: PropTypes.object,
  setCurrentDate: PropTypes.func.isRequired,
};

GridView.defaultProps = {
  calendarRef: null,
};

const Moves = ({ calendarRef, setCurrentDate, buttonColor }) => {
  const prev = () => {
    calendarRef.current.getApi().prev();
    setCurrentDate(calendarRef.current.getApi().view.title);
  };

  const next = () => {
    calendarRef.current.getApi().next();
    setCurrentDate(calendarRef.current.getApi().view.title);
  };

  return (
    <ButtonGroup variant="contained" color="secondary">
      <Button onClick={() => prev()} style={{ backgroundColor: buttonColor }}>
        <NavigateBefore />
      </Button>
      <Button onClick={() => next()} style={{ backgroundColor: buttonColor }}>
        <NavigateNext />
      </Button>
    </ButtonGroup>
  );
};

Moves.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  calendarRef: PropTypes.object,
  setCurrentDate: PropTypes.func.isRequired,
};

Moves.defaultProps = {
  calendarRef: null,
};

const Header = ({
  calendarRef,
  buttonColor,
  calendarDates,
  setCalendarDates,
  hidePrograms,
  hideDateField,

  calendarType,
  setCalendarType,
}) => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const [date, setDate] = useState(parseDate(new Date()).format('MM-DD-YYYY'));
  const [open, setOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(false);

  const goToDate = (newDate) => {
    calendarRef.current
      ?.getApi()
      ?.gotoDate(parseDate(newDate, 'MM-DD-YYYY HH:mm a Z').format('YYYY-MM-DD'));
    setDate(parseDate(newDate, 'MM-DD-YYYY HH:mm a Z').format('MM-DD-YYYY'));
    setCurrentDate(calendarRef.current?.getApi()?.view?.title);
  };

  useEffect(() => {
    if (calendarRef && calendarRef.current?.getApi()) {
      setDate(parseDate(calendarRef.current?.getApi()?.getDate()).format('MM-DD-YYYY'));
    }
  }, [calendarDates, calendarRef]);

  useEffect(() => {
    if (calendarRef && calendarRef?.current?.getApi()) {
      setCurrentDate(calendarRef?.current?.getApi()?.view?.title);
      setCalendarDates((prev) => ({
        ...prev,
        currentView: calendarRef.current.getApi().view.type,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarRef]);

  return (
    <Box py={3} my={3}>
      <>
        <Hidden mdUp>
          <Box display="flex" flexGrow={1} flexDirection="column" mb={1} alignItems="center">
            {currentDate ? (
              <Typography variant="h3">{currentDate}</Typography>
            ) : (
              <Skeleton animation="wave" height={50} width="100%" />
            )}
          </Box>
        </Hidden>

        <Hidden only={['xs', 'sm']}>
          <Box display="flex" flexGrow={1}>
            <Box>
              <Moves
                calendarRef={calendarRef}
                setCurrentDate={setCurrentDate}
                buttonColor={buttonColor}
              />
            </Box>
            <Box mx={2}>
              <Toggle
                options={[
                  { value: 'workouts', label: 'Classes' },
                  { value: 'events', label: 'Events' },
                ]}
                onChange={(v) => setCalendarType(v)}
                value={calendarType}
                activeColor={buttonColor || primary}
                color="white"
                className="tw-h-[36px]"
              />
            </Box>

            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Hidden only={['xs']}>
                <Box display="flex" flexGrow="1" mb={3}>
                  <Box display="flex" flexGrow="1" justifyContent="center">
                    {currentDate ? (
                      <Typography variant="h3">{currentDate}</Typography>
                    ) : (
                      <Skeleton animation="wave" height={50} width="100%" />
                    )}
                  </Box>
                </Box>
              </Hidden>
              {!hideDateField && (
                <Box>
                  <DatePickerRounded
                    onChange={(v) => goToDate(v)}
                    value={date}
                    disablePast={false}
                    usFormat={channel?.useUsDateFormat}
                    autoOk
                  />
                </Box>
              )}
            </Box>

            <Box>
              <GridView
                calendarRef={calendarRef}
                setCurrentDate={setCurrentDate}
                setCalendarDates={setCalendarDates}
                calendarDates={calendarDates}
                buttonColor={buttonColor}
                hidePrograms={hidePrograms}
              />
            </Box>
          </Box>
        </Hidden>
        <Hidden only={['md', 'lg', 'xl']}>
          <div className="tw-flex tw-flex-col tw-gap-2">
            <div className="tw-flex tw-items-center tw-justify-center">
              <GridView
                calendarRef={calendarRef}
                setCurrentDate={setCurrentDate}
                setCalendarDates={setCalendarDates}
                calendarDates={calendarDates}
                buttonColor={buttonColor}
                hidePrograms={hidePrograms}
              />
            </div>

            <div className="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-gap-2">
              {setCalendarType && (
                <Box>
                  <Toggle
                    options={[
                      { value: 'workouts', label: 'Classes' },
                      { value: 'events', label: 'Events' },
                    ]}
                    onChange={(v) => setCalendarType(v)}
                    value={calendarType}
                    activeColor={buttonColor || primary}
                    color="white"
                    className="tw-h-[36px]"
                  />
                </Box>
              )}
              {!hideDateField && (
                <div className="tw-flex tw-grow tw-items-center tw-justify-center">
                  <div>
                    <DatePickerRounded
                      onChange={(v) => goToDate(v)}
                      value={date}
                      usFormat={channel?.useUsDateFormat}
                      disablePast={false}
                      autoOk
                    />
                  </div>
                </div>
              )}

              <Moves calendarRef={calendarRef} setCurrentDate={setCurrentDate} />
            </div>
          </div>
        </Hidden>
      </>

      <WorkoutForm open={open} setOpen={setOpen} />
    </Box>
  );
};

Header.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  calendarRef: PropTypes.object,
};

Header.defaultProps = {
  calendarRef: null,
};

export default Header;
