import { lazy } from 'react';

const FormNotFound = lazy(() => import('./FormNotFound'));

const FormChannelSignup = lazy(() => import('./FormChannelSignup'));
const FormWait = lazy(() => import('./FormWait'));
const FormTotalClasses = lazy(() => import('./FormTotalClasses'));
const FormSendPushNotification = lazy(() => import('./FormSendPushNotification'));
const FormSendSms = lazy(() => import('./FormSendSms'));
const FormSendEmail = lazy(() => import('./FormSendEmail'));
const FormRegistrationLapse = lazy(() => import('./FormRegistrationLapse'));
const FormDocumentSigned = lazy(() => import('./FormDocumentSigned'));
const FormCreateMember = lazy(() => import('./FormCreateMember'));
const FormAddWorkflow = lazy(() => import('./FormAddWorkflow'));
const FormInternalNotification = lazy(() => import('./FormInternalNotification'));
const FormMembershipPurchase = lazy(() => import('./FormMembershipPurchase'));
const FormMembershipLimit = lazy(() => import('./FormMembershipLimit'));
const FormMembershipCancelled = lazy(() => import('./FormMembershipCancelled'));
const FormStages = lazy(() => import('./FormStages'));
const FormStagesChanges = lazy(() => import('./FormStagesChanges'));
const FormNewGroupMessage = lazy(() => import('./FormNewGroupMessage'));
const FormNewChannelEventRegistration = lazy(() => import('./FormNewChannelEventRegistration'));
const FormNoShowClasses = lazy(() => import('./FormNoShowClasses'));
const FormEngageFlow = lazy(() => import('./FormEngageFlow'));
const FormCalendarBooking = lazy(() => import('./FormCalendarBooking'));
const FormChatResponse = lazy(() => import('./FormChatResponse'));
const FormStorePurchase = lazy(() => import('./FormStorePurchase'));
const FormMembershipExpiring = lazy(() => import('./FormMembershipExpiring'));
const FormRemoveFromWorkflow = lazy(() => import('./FormRemoveFromWorkflow'));
const FormContactForm = lazy(() => import('./FormContactForm'));

export const getFormComponent = (automationTypeFunction) => {
  switch (automationTypeFunction) {
    case 'ChannelSignup':
      return FormChannelSignup;
    case 'Wait':
      return FormWait;
    case 'TotalClasses':
      return FormTotalClasses;
    case 'SendPushNotification':
      return FormSendPushNotification;
    case 'SendSms':
      return FormSendSms;
    case 'SendEmail':
      return FormSendEmail;
    case 'RegistrationLapse':
      return FormRegistrationLapse;
    case 'DocumentSigned':
      return FormDocumentSigned;
    case 'CreateMember':
      return FormCreateMember;
    case 'AddWorkflow':
      return FormAddWorkflow;
    case 'InternalNotification':
      return FormInternalNotification;
    case 'MembershipPurchase':
      return FormMembershipPurchase;
    case 'MembershipLimit':
      return FormMembershipLimit;
    case 'MembershipCancelled':
      return FormMembershipCancelled;
    case 'Stages':
      return FormStages;
    case 'StagesChanges':
      return FormStagesChanges;
    case 'NewGroupMessage':
      return FormNewGroupMessage;
    case 'NewChannelEventRegistration':
      return FormNewChannelEventRegistration;
    case 'NoShowClasses':
      return FormNoShowClasses;
    case 'EngageFlow':
      return FormEngageFlow;
    case 'CalendarBooking':
      return FormCalendarBooking;
    case 'ChatResponse':
      return FormChatResponse;
    case 'StorePurchase':
      return FormStorePurchase;
    case 'MembershipExpiring':
      return FormMembershipExpiring;
    case 'RemoveFromWorkflow':
      return FormRemoveFromWorkflow;
    case 'ContactForm':
      return FormContactForm;
    default:
      return FormNotFound;
  }
};
