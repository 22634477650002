/* eslint-disable react/prop-types */
import { useCallback, useEffect, useState } from 'react';
import { parseDate, date, formatDateToApi } from 'core/utils/formatters';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { showAPIError } from 'core/utils/showAPIError';
import useWindowSize from 'core/utils/hook/useWindowSize';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useConfirmation from 'core/useConfirmation';
import { eventColor, eventTextColor } from './eventMethods';

const useCalendarEvents = ({ gymArea, usersCalendarId, coachId, channel }) => {
  const { request } = useActions();
  const [calendarRef, setCalendarRef] = useState(null);
  const [calendarDates, setCalendarDates] = useState({ current: true });
  const [modalRegistration, setModalRegistration] = useState({ open: false });
  const [modalEvent, setModalEvent] = useState({ open: false });
  const [pagination, setPagination] = useState({ current: true });
  const [perPage, setPerPage] = useState(10);

  const { t } = useTranslation();

  const { confirmation } = useConfirmation();

  const { control, getValues, setValue, watch, handleSubmit } = useForm({
    defaultValues: { coachIds: [coachId] },
  });

  const { height } = useWindowSize();

  const [events, setEvents] = useState([]);
  const [registrationEvents, setRegistrationEvents] = useState([]);

  const setEventsByFilters = useCallback((data) => {
    const evs = data
      ? data.map((event) => {
          const start = parseDate(event.startAt, null, event?.usersCalendar?.timezone).format(
            'YYYY-MM-DD HH:mm:ss',
          );
          const end = parseDate(event.endAt, null, event?.usersCalendar?.timezone).format(
            'YYYY-MM-DD HH:mm:ss',
          );

          return {
            id: event.id,
            backgroundColor: eventColor(event),
            borderColor: eventColor(event),
            display: 'block',
            start,
            end,
            textColor: eventTextColor(event),
            extendedProps: {
              ...event,
            },
          };
        })
      : [];

    setEvents(evs);
  }, []);

  const getCalendarsRegistrationsEvents = useCallback(
    (page = 1) => {
      if (!channel?.id) {
        return;
      }
      const data = getValues();
      const filterParams = {
        q: {
          start_at_gteq: data?.startDate && formatDateToApi(data?.startDate),
          start_at_lteq: data?.endDate && formatDateToApi(data?.endDate),
          users_calendar_id_eq: usersCalendarId || data?.usersCalendarId,
          users_calendar_user_id_in: data?.coachIds,
          workouts_type_id_eq: data?.workoutsTypeId,
          users_calendar_channels_workouts_type_id_eq: data?.workoutsTypeId,
          user_name_cont: data?.userName,
        },
      };

      request({
        action: Modules.usersCalendarsRegistrations.actions.getRegistrations,
        data: {
          gymArea,
          coachIds: data?.coachIds,
          channelId: channel.id,
          usersCalendarId,
          page,
          perPage,
          ...filterParams,
        },
        options: {
          onSuccess: (resp, pag) => {
            setRegistrationEvents(resp);
            setPagination(pag);
          },
          onError: showAPIError,
        },
      });
    },
    [channel, request, getValues, perPage, usersCalendarId, gymArea],
  );

  const getCalendarsEvents = useCallback(() => {
    if (!channel?.id) {
      return;
    }
    setValue('startDate', date(calendarDates.start));
    setValue('endDate', date(calendarDates.end));

    const data = getValues();
    const filterParams = {
      q: {
        usersCalendarId: usersCalendarId || data?.usersCalendarId,
        user_name_cont: data?.userName,
      },
    };

    request({
      action: Modules.usersCalendars.actions.getCalendarsEvents,
      data: {
        gymArea,
        coachIds: data?.coachIds,
        channelId: channel.id,
        startDate: calendarDates.start,
        endDate: calendarDates.end,
        usersCalendarId: usersCalendarId || data?.usersCalendarId,
        workoutsTypeId: data?.workoutsTypeId,
        ...filterParams,
      },
      options: {
        onSuccess: (resp) => setEventsByFilters(resp),
        onError: showAPIError,
      },
    });
  }, [
    calendarDates,
    request,
    channel?.id,
    setEventsByFilters,
    setValue,
    getValues,
    usersCalendarId,
    gymArea,
  ]);

  useEffect(() => {
    if (calendarDates.start) {
      getCalendarsEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarDates?.start, usersCalendarId, channel?.id]);

  const handleEventClick = (info) => {
    const { event } = info;

    if (info.el.focus) {
      info.el.focus();
    }

    if (event?.extendedProps?.type === 'slot') {
      setModalRegistration({ open: true, event: event?.extendedProps });
    }
    if (event?.extendedProps?.type === 'registration') {
      setModalEvent({ open: true, event: event?.extendedProps });
    }
  };

  const calendarProps = { height: height - 250 };

  const submit = useCallback(
    (data) => {
      if (data?.viewType === 'registrations') {
        getCalendarsRegistrationsEvents();
      } else {
        getCalendarsEvents();
      }
    },
    [getCalendarsEvents, getCalendarsRegistrationsEvents],
  );

  const confirmRegistration = (reg, callback = null) => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        request({
          action: Modules.usersCalendarsRegistrations.actions.updateRegistration,
          data: {
            status: 'confirmed',
            usersCalendarId: reg?.usersCalendar?.id,
            channelId: channel.id,
            id: reg?.id,
          },
          options: {
            onSuccess: () => {
              getCalendarsEvents();
              if (callback) {
                callback();
              }
            },
            onError: showAPIError,
          },
        });
      },
    });
  };

  useEffect(() => {
    if (usersCalendarId) {
      setValue('usersCalendarId', usersCalendarId);
    }
  }, [usersCalendarId, setValue]);

  return {
    handleSubmit,
    submit,
    setValue,
    control,
    watch,

    registrationEvents,
    perPage,
    setPerPage,
    pagination,
    getCalendarsRegistrationsEvents,
    modalRegistration,
    setModalRegistration,
    getCalendarsEvents,
    modalEvent,
    setModalEvent,
    confirmRegistration,
    setCalendarDates,
    calendarDates,

    calendarRef,
    setCalendarRef,

    events,

    handleEventClick,
    calendarProps,
  };
};

export default useCalendarEvents;
