import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Chip } from '@material-ui/core';
import Box from 'core/ui/Box';
import { workoutsSelectors } from 'modules/workouts';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { sessionSelectors } from 'modules/session';
import { isMobile } from 'react-device-detect';
import ModalDownloadApp from 'core/ui/ModalDownloadApp';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import { redColor } from 'core/ui/Colors';
import ModalCancelRegister from './ModalCancelRegister';
import useActionsButtons from './useActionsButtons';

const useStyles = makeStyles((theme) => ({
  boxButtons: {
    position: 'absolute',
    top: 1,
    right: -5,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      right: 0,
      display: 'flex',
      justifyContent: 'center',
    },
  },
  colorTitle: {
    color: '#404041',
  },
  boxRating: {
    position: 'absolute',
    marginTop: '-50px',
    marginLeft: 40,
  },
  boxRegister: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  buttons: {
    color: '#fff',
    minWidth: 130,
    marginLeft: 1,
  },
  boxButtonsHeader: {
    position: 'absolute',
    marginTop: '-60px',
    right: 30,
    cursor: 'pointer',
  },
}));

const ButtonActions = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));

  const buttonColor = workout?.channel?.customColors?.buttonColor;

  const {
    openModalDownloadApp,
    setOpenModalDownloadApp,
    dialogConfirm,
    setDialogCancel,
    confirmCancel,
    dialogConfirmReset,
    setDialogConfirmReset,
    resetConfirm,
    showRegisterButton,
    registerClick,
    cancelInvite,
    uncancelClass,
    loading,
    removeFromWaitList,

    ButtonLogin,
    ButtonsJoinRefund,
    ProcessingRecordings,
    ButtonCheckin,
    ButtonJoinWorkout,
  } = useActionsButtons(workout);

  return (
    <Box className={classes.boxButtons}>
      {isMobile && (
        <ModalDownloadApp
          open={openModalDownloadApp}
          close={() => setOpenModalDownloadApp(false)}
        />
      )}

      <ModalCancelRegister
        open={dialogConfirm.open}
        close={() => setDialogCancel((prev) => ({ ...prev, open: false }))}
        workout={workout}
        confirmCancel={confirmCancel}
      />

      <ConfirmDialog
        open={dialogConfirmReset.open}
        setOpen={(v) => setDialogConfirmReset((prev) => ({ ...prev, open: v }))}
        title=""
        description={t('competition.workout.replace')}
        labelConfirm={t('button.yes')}
        labelCancel={t('button.no')}
        confirm={() => resetConfirm()}
      />

      <Box display="flex" px={11} mt={2}>
        {showRegisterButton() && (
          <>
            <ButtonPrimary
              buttonColor={buttonColor}
              className={classes.buttons}
              onClick={() => registerClick()}
            >
              {t(
                // eslint-disable-next-line no-nested-ternary
                workout?.registered
                  ? 'button.registerChild'
                  : workout?.hasInvite
                    ? 'button.accept'
                    : 'button.register',
              ).toUpperCase()}
            </ButtonPrimary>

            {workout?.hasInvite && (
              <ButtonPrimary
                style={{ marginLeft: 5 }}
                buttonColor={redColor}
                className={classes.buttons}
                onClick={() => cancelInvite()}
              >
                {t('button.decline').toUpperCase()}
              </ButtonPrimary>
            )}
          </>
        )}
        {!isAuthenticated && <ButtonLogin style={{ width: '100%' }} />}

        {workout?.isCoach && workout?.canceled && (
          <ButtonPrimary
            style={{ marginLeft: 5 }}
            buttonColor={redColor}
            className={classes.buttons}
            onClick={() => uncancelClass(workout)}
          >
            {t('button.uncancel').toUpperCase()}
          </ButtonPrimary>
        )}

        {workout?.isInWaitlist && (
          <ButtonPrimary
            style={{ marginLeft: 5 }}
            buttonColor={buttonColor}
            className={classes.buttons}
            onClick={() => removeFromWaitList(currentUser?.id)}
          >
            {t('button.waitlisted').toUpperCase()}
          </ButtonPrimary>
        )}

        {workout?.recordingStatus === 'processing' ? (
          <Box mt={3} display="flex" justifyContent="center">
            <Chip
              icon={<LoaderSm loading />}
              color="primary"
              label={t('workout.recording.processing')}
            />
          </Box>
        ) : (
          <>
            {!workout?.isCompetition &&
              (workout?.registered || workout?.registeredChild) &&
              !loading && (
                <>
                  {workout.allowSelfCheckIn &&
                    !workout?.checkedIn &&
                    workout?.registered &&
                    !workout?.isCoach && <ButtonCheckin />}
                  <ButtonsJoinRefund />
                  <ProcessingRecordings />
                </>
              )}
            {workout?.isCompetition && workout?.allowJoinWorkout && <ButtonJoinWorkout />}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ButtonActions;
